<script setup lang="ts">
import type { ISearchPropertyLeasePortfolioRequest } from '@portfolio'

// PROPS
withDefaults(defineProps<{ showAdvancedFilter?: boolean }>(), {
  showAdvancedFilter: true,
})
const filters = defineModel<ISearchPropertyLeasePortfolioRequest>({
  default: () => ({}),
  required: true,
})
const visible = defineModel<boolean>('visible', { default: false })

const hide = () => {
  visible.value = false
}

const onConfirm = () => {
  hide()
}
const onClose = () => {
  hide()
}
</script>

<template>
  <LazySidebarOverlay v-model="visible">
    <LazyLeaseFilters
      v-model="filters"
      :show-advanced-filter="showAdvancedFilter"
      @confirm="onConfirm()"
      @close="onClose()"
    />
  </LazySidebarOverlay>
</template>
